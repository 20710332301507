import {
  CREATE_PARTNER_USER_TOKEN,
  EVENTS_SEARCH_API,
  GET_BEST_AVAILABLE_PRICE_META_DATA_URL_V2,
  GET_BEST_AVAILABLE_PRICE_URL_V3,
  GET_PARTNER_WIDGET_DETAILS,
  GET_PAYMENT_CONFIG_DETAILS,
  GET_PUBLIC_EVENT_DETAILS_URL,
  httpClient,
  INITIATE_ORDER_URL,
} from '../../config/api';
import { getPartnerId, getSourceName } from '../../config/helper';

export type OrderServiceProps = {
  event_id?: number;
  invested_amount?: number;
  l1_order_investment?: number;
  offer_type?: string;
  order_type?: string;
  l1_order_quantity?: number;
  l1_expected_price?: number;
  match_price_limit?: number;
  order_price?: number;
  response?: any;
};

export const getCategoryAndWidgetTypeUsingPartnerAccessAndPlacementId = async (
  placementID: any,
  accessId: any,
) => {
  return httpClient.get({
    url: `${GET_PARTNER_WIDGET_DETAILS}/${placementID}`,
    token: true,
    headers: {
      'x-partner-key': accessId,
    },
  });
};

export const getEventsDataBasedOnPartnerCategory = async (data = {}) => {
  return httpClient.post({
    url: EVENTS_SEARCH_API,
    data,
    token: false,
    headers: {
      'x-partner-id': getPartnerId(),
    },
  });
};

export const getPublicEventDetails = async (eventId: number) => {
  return httpClient.get({
    url: `${GET_PUBLIC_EVENT_DETAILS_URL}${eventId}`,
    token: true,
    headers: {
      'x-partner-id': getPartnerId(),
    },
  });
};

export const getPaymentConfig = async () => {
  return httpClient.get({
    url: `${GET_PAYMENT_CONFIG_DETAILS}`,
    token: true,
    headers: {
      'x-partner-id': getPartnerId(),
    },
  });
};

export const getBestAvailablePriceMetaDataV2 = async (eventIds: number) => {
  return httpClient.get({
    url: `${GET_BEST_AVAILABLE_PRICE_META_DATA_URL_V2}?eventIds=${eventIds}`,
    token: true,
    headers: {
      'x-partner-id': getPartnerId(),
    },
  });
};

export const getBestAvailablePriceV3 = async (eventIds: number) => {
  return httpClient.get({
    url: `${GET_BEST_AVAILABLE_PRICE_URL_V3}?eventId=${eventIds}`,
    token: true,
    headers: {
      'x-partner-id': getPartnerId(),
    },
  });
};

export const initiateOrder = async (data: OrderServiceProps) => {
  return httpClient.post({
    url: `${INITIATE_ORDER_URL}`,
    data,
    token: true,
  });
};

export const createPartnerUserToken = async (accessId: any, partnerAuthToken: any) => {
  return httpClient.post({
    url: `${CREATE_PARTNER_USER_TOKEN}`,
    token: true,
    headers: {
      'x-partner-token': partnerAuthToken,
      'x-partner-api-key': accessId,
      'x-partner-name': getSourceName(),
    },
  });
};
