import { handlePartnerLogin } from '../../../config/helper';

import styles from './style.module.scss';

type PartnerLoginProps = {
  hasPartnerLoggedIn?: boolean;
};

const PartnerLogin = (props: PartnerLoginProps) => {
  const { hasPartnerLoggedIn } = props || {};

  return !hasPartnerLoggedIn ? (
    <div
      className={styles['partner__login__to__continue__button']}
      onClick={() => handlePartnerLogin()}
    >
      Login to Continue
    </div>
  ) : null;
};

export default PartnerLogin;
