import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';

import PartnerCongratulation from '../../../assets/json/partner-congratulations.json';
import OrderPlacedPartnerImage from '../../../assets/icons/order-placed-partner.svg';

import { APP_ENVIRONMENTS, URL_PARAMS_KEY } from '../../../config/constants';
import { getEventsData, resolveTargetOrigin } from '../../../config/helper';

import PartnerLayout from '../PartnerLayout';
import PartnerHeader from '../PartnerHeader';

import styles from './style.module.scss';

const SuccessCard = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionType: string | null = urlParams.get(URL_PARAMS_KEY.ACTION_TYPE);
  const eventId: number = Number(urlParams.get(URL_PARAMS_KEY.EVENT_ID));
  let eventData: any = getEventsData();

  const [successCardPageHeight, setSuccessCardPageHeight] = useState('100%');

  const heightRef: any = useRef(null);

  if (eventData) {
    eventData = JSON.parse(eventData);
  }

  const hyperLinksData: any = eventData[eventId]?.hyperlinks;

  const hasOneHyperLink = hyperLinksData?.length === 1;

  const handleButtonClick = (url: string) => {
    const data = {
      type: 'url',
      url,
    };

    const targetOrigins = resolveTargetOrigin();

    if (targetOrigins) {
      targetOrigins.forEach((targetOrigin) => {
        (window as any).top.postMessage(data, targetOrigin);
      });
    }
  };

  useEffect(() => {
    if (heightRef?.current) {
      // Delay the height calculation to ensure the DOM is fully rendered
      const timer = setTimeout(() => {
        if (heightRef.current) {
          // 4px is hack to remove scroll on iframe
          setSuccessCardPageHeight(heightRef.current.clientHeight + 4);
        }
      }, 100); // Adjust the delay as needed

      // Clean up the timer when the component is unmounted or event changes
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <PartnerLayout height={successCardPageHeight} heightRef={heightRef}>
      <PartnerHeader partnerEventCardProps={eventData[eventId]} showTraders={false} />
      <div style={{ marginTop: '24px' }}>
        <Player
          autoplay
          loop
          src={PartnerCongratulation}
          style={{ height: '250px', width: '90%', position: 'absolute', top: '0px' }}
        ></Player>
        <img
          src={OrderPlacedPartnerImage}
          width="66px"
          height="66px"
          className={styles['partner__order__placed__image']}
        />
        <div className={styles['partner__login__msg']}>
          <p
            className={styles['heading']}
            style={{
              color: '#0066FF',
            }}
          >
            Order Placed at {actionType}
          </p>
        </div>

        {hyperLinksData?.length > 0 && (
          <div
            className={classNames({
              [styles['partner__hyperlinks']]: true,
              [styles['partner__hyperlinks__for__one__data']]: hasOneHyperLink,
            })}
          >
            {hyperLinksData?.map((item: any) => {
              const { url, text, color, bgColor } = item || {};

              return (
                <button
                  className={styles['partner__hyperlinks__btn']}
                  style={{
                    backgroundColor: bgColor,
                    color: color,
                  }}
                  onClick={() => handleButtonClick(url)}
                  key={url}
                >
                  {text}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </PartnerLayout>
  );
};

export default SuccessCard;
