import classNames from 'classnames';
// import { motion } from 'framer-motion/dist/framer-motion';
import { Button } from '@mui/material';

export type ButtonProps = {
  id?: string;
  style?: object;
  className?: string;
  name?: string | React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode;
  primary?: boolean;
  blackVariant?: boolean;
  secondary?: boolean;
  secondaryV2?: boolean;
  disabled?: boolean;
  link?: boolean;
  fullWidth?: boolean;
  icon?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconLeft?: any;
  iconClassName?: string;
};

const MuiButton = ({
  id,
  style,
  className,
  name,
  type,
  children,
  onClick,
  blackVariant,
  primary,
  secondary,
  secondaryV2,
  link,
  icon,
  disabled,
  fullWidth,
  iconLeft,
  iconClassName,
}: ButtonProps) => {
  const classes: any = {
    btn: true,
  };

  if (blackVariant) classes.btn__black__variant = true;
  else if (primary) classes.btn__primary = true;
  else if (secondary) classes.btn__secondary = true;
  else if (link) classes.btn__link = true;
  else if (secondaryV2) classes.btn__secondaryV2 = true;

  if (disabled) classes.btn__disabled = true;

  if (fullWidth) classes.btn__fullWidth = true;

  if (className) classes[className] = className;

  return (
    <Button id={id} className={classNames(classes)} style={style} type={type} onClick={onClick}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {icon && (
          <img
            src={icon}
            alt=""
            className={iconClassName}
            style={{ marginRight: 5, opacity: disabled ? 0.5 : 1 }}
          />
        )}{' '}
        {name || children}{' '}
        {iconLeft && (
          <img
            src={iconLeft}
            className={iconClassName}
            alt=""
            style={{ marginLeft: 10, opacity: disabled ? 0.5 : 1 }}
          />
        )}
      </div>
    </Button>
  );
};

export default MuiButton;
