import { LOCAL_STORAGE_KEY, EVENT_TYPE } from './constants';

const globalThis: any = window;

// Get Partner Auth Data
export const getPartnerAuthToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.PARTNER_AUTH_TOKEN_KEY);
};

// Set Partner Auth Data
export const setPartnerAuthToken = (partnerAuthToken: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.PARTNER_AUTH_TOKEN_KEY, partnerAuthToken);
};

// Get Partner Id
export const getPartnerId = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.PARTNER_ID_KEY);
};

// Set Partner Id
export const setPartnerId = (partnerId: number) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.PARTNER_ID_KEY, partnerId as unknown as string);
};

// Get Source Name
export const getSourceName = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.SOURCE_NAME_KEY);
};

// Set Source Name
export const setSourceName = (sourceName: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.SOURCE_NAME_KEY, sourceName);
};

export const getEventsData = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.EVENT_DATA_KEY);
};

export const setEventsData = (data: any) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.EVENT_DATA_KEY, data);
};

// Set User OS
export const setUserOs = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const mac = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const ios = ['iPhone', 'iPad', 'iPod', 'iPhone Simulator', 'iPad Simulator'];
  let os: any = null;

  if (mac.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (ios.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windows.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  localStorage.setItem(LOCAL_STORAGE_KEY.USER_OS_KEY, os);
};

// Get User OS
export const getUserOs = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.USER_OS_KEY);
};

export const getPlatformType = () => {
  if (
    typeof globalThis?.proboAndroidWebView !== 'undefined' &&
    globalThis?.proboAndroidWebView !== null
  ) {
    return 'android';
  } else if (globalThis?.webkit?.messageHandlers?.probo_ios_handler) {
    return 'iOS';
  } else {
    const userOs: string | any = getUserOs();
    if (['Mac', 'Windows', 'Linux'].includes(userOs)) return 'd_web';
    else return 'm_web';
  }
};

export const resolveOneLinerVersion = (version?: string, oneLiner?: any, oneLinerV2?: any) => {
  if (version === 'oneliner_v2' && oneLinerV2) {
    return oneLinerV2;
  }
  if (oneLiner && Object.keys(oneLiner)) {
    return oneLiner;
  }
  return null;
};

export const resolveOneLinerText = (text: string | null, suffix: string | null) => {
  if (!text) return '';

  if (suffix && !suffix.startsWith('.')) {
    return `${text?.trim()} `;
  }

  return text?.trim();
};

export const resolveTargetOrigin = (): string[] | undefined => {
  // const partnerSourceName = getSourceName() as PARTNER_SOURCE_NAME;

  // // Ensure partnerSourceName is a valid key in PARTNER_TARGET_ORIGIN_URL_MAPPING
  // if (!(partnerSourceName in PARTNER_TARGET_ORIGIN_URL_MAPPING)) {
  //   console.error(`Partner source name '${partnerSourceName}' is not valid.`);
  //   return undefined;
  // }

  // const targetUrls = PARTNER_TARGET_ORIGIN_URL_MAPPING[partnerSourceName];
  const targetUrls = process.env.REACT_APP_PARTNERS_URLS?.split(',');

  if (!targetUrls || targetUrls.length === 0) {
    console.error(`No URLs found`);
    return undefined;
  }

  return targetUrls;
};

export const handlePartnerLogin = () => {
  const eventData = {
    type: EVENT_TYPE.OPEN_LOGIN_PAGE,
  };

  const targetOrigins = resolveTargetOrigin();

  if (targetOrigins) {
    targetOrigins.forEach((targetOrigin) => {
      (window as any).top.postMessage(eventData, targetOrigin);
    });
  }
};
