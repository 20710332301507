import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { resolveTargetOrigin } from '../../../config/helper';
import { EVENT_TYPE } from '../../../config/constants';

export type PartnerLayoutProps = {
  height?: string | number;
  heightRef?: any;
  children?: React.ReactElement | React.ReactElement[] | any;
};

const PartnerLayout = ({ children, height = '100%', heightRef }: PartnerLayoutProps) => {
  const eventData = {
    type: EVENT_TYPE.HEIGHT_CHANGE,
    height: Number(height),
  };

  const targetOrigins = resolveTargetOrigin();

  useEffect(() => {
    if (targetOrigins) {
      targetOrigins.forEach((targetOrigin) => {
        (window as any).top.postMessage(eventData, targetOrigin);
      });
    }
  }, [height]);

  return (
    <div
      style={{
        height: typeof height === 'number' ? `${height}px` : height,
        padding: '9px 20px',
        background: 'white',
        width: '100%',
        borderRadius: '4px 4px 0px 0px',
        border: '2px solid #f5f5f5',
        position: 'relative',
      }}
      ref={heightRef}
    >
      {children}
      <ToastContainer style={{ width: 'auto' }} />
    </div>
  );
};

export default PartnerLayout;
