import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Tooltip } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import InsufficentBalance from '../../../assets/icons/insufficent-balance.svg';

import {
  getBestAvailablePriceMetaDataV2,
  getBestAvailablePriceV3,
  initiateOrder,
} from '../../../services/partner';

import { getEventsData, getPartnerAuthToken, getSourceName } from '../../../config/helper';
import { RECHARGE_URL } from '../../../config/api';
import {
  ACTION_TYPE,
  APP_ROUTES,
  EVENT_TYPE,
  OFFER_TYPE,
  ORDER_TYPE,
  URL_PARAMS_KEY,
} from '../../../config/constants';

import MuiButton from '../../../components/MuiButton';
import { Toast } from '../../../components/Toast';

import PartnerLayout from '../PartnerLayout';
import PartnerHeader from '../PartnerHeader';

import styles from './style.module.scss';

const styledSliderShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const StyledSlider = styled(Slider)(({ theme, tourEnabled }: any) => {
  const thumbProps: any = {
    height: 12,
    width: 12,
    backgroundColor: 'rgba(38,38,38,1)',
    // backgroundImage: `url(${logo})`,
    boxShadow: styledSliderShadow,
    backgroundSize: 'cover',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: styledSliderShadow,
      },
    },
    '&:before': {
      border: '4px solid #fff',
    },
  };

  if (tourEnabled) {
    thumbProps.height = 50;
    thumbProps.width = 32;
    thumbProps.backgroundColor = 'transparent';
    thumbProps.boxShadow = 'none';
    thumbProps.top = '75%';
    thumbProps.borderRadius = '0';
    thumbProps['&:before'] = {
      display: 'none',
    };
  }

  return {
    height: 2,
    padding: '0',
    '& .MuiSlider-thumb': thumbProps,
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: '#fff',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#E3E3E3',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#E3E3E3',
      height: 12,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
  };
});

const PartnerPriceSheet = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionType: string | null = urlParams.get(URL_PARAMS_KEY.ACTION_TYPE);
  const eventId: number = Number(urlParams.get(URL_PARAMS_KEY.EVENT_ID));
  let eventData: any = getEventsData();

  if (eventData) {
    eventData = JSON.parse(eventData);
  }

  const navigate = useNavigate();

  const [commission, setCommission] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  const [lowBalanceNudge, setLowBalanceNudge] = useState<any>(null);
  const [bestAvailablePriceData, setBestAvailablePriceData] = useState<any>(null);
  const [price, setPrice] = useState<any>(0);
  const [quantity, setQuantity] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [priceSheetPageHeight, setPriceSheetPageHeight] = useState<string | number>('100%');
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [initialHeight, setInitialHeight] = useState('100%');
  const [insufficientBalanceOnInitialLoad, setInsufficientBalanceOnInitialLoad] = useState(false);
  const [eventType, setEventType] = useState('');

  const heightRef: any = useRef(null);

  let totalAmount: number = 0;
  let totalTradePrice: number = 0;
  let potentialWin: number = 0;

  if (price && quantity) {
    totalAmount = price * quantity;
  }

  if (quantity) {
    totalTradePrice = 10 * quantity;
    potentialWin = totalTradePrice - totalAmount;
  }

  const isBalanceInsufficient = balance?.totalUsableAmount < totalAmount;

  const handlePlaceOrder = async (eventId: number) => {
    setIsLoading(true);
    const response = await initiateOrder({
      event_id: eventId,
      offer_type: actionType === ACTION_TYPE.YES ? OFFER_TYPE.BUY : OFFER_TYPE.SELL,
      order_type: ORDER_TYPE.LO,
      l1_order_quantity: quantity,
      l1_expected_price: price,
    });

    const { isError, error } = response || {};

    if (isError) {
      Toast({
        message: error?.error_message,
        type: 'error',
      });
    } else {
      let url: string = APP_ROUTES.PARTNER_SUCCESS;

      if (actionType) {
        url = `${url}?${URL_PARAMS_KEY.ACTION_TYPE}=${actionType}&${URL_PARAMS_KEY.EVENT_ID}=${eventId}`;
      }

      navigate(url);
    }

    setIsLoading(false);
  };

  const quantityChangeHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setQuantity(parseInt(e?.target?.value));
  };

  const adjustHeightOnInitialLoad = () => {
    if (heightRef?.current?.clientHeight) {
      // 7px is hack to remove scroll on iframe
      setPriceSheetPageHeight(heightRef.current.clientHeight + 7);
      setInitialHeight(heightRef.current.clientHeight + 7);
      setInsufficientBalanceOnInitialLoad(isBalanceInsufficient);
    }
  };

  const getBestAvailablePriceMetaData = async (eventId: number) => {
    const response = await getBestAvailablePriceMetaDataV2(eventId);

    if (response && !response.isError) {
      if (!response?.data) {
        Toast({
          message: 'This event is expired. Please choose another event for trading.',
          type: 'error',
        });
      } else {
        const { data } = response || {};

        if (data && data.length > 0) {
          const { commission, low_balance_nudge, wallet_amount_details } = data[0] || {};

          setCommission(commission);
          setBalance(wallet_amount_details);
          setLowBalanceNudge(low_balance_nudge);
        }
      }
    }
  };

  const getBestAvailablePriceData = async (eventId: number) => {
    const response = await getBestAvailablePriceV3(eventId);

    if (response && !response.isError) {
      if (!response?.data) {
        Toast({
          message: 'This event is expired. Please choose another event for trading.',
          type: 'error',
        });
      } else {
        const { data } = response || {};
        setBestAvailablePriceData(data);
        setPrice(
          actionType === ACTION_TYPE.YES
            ? data?.order_options?.buy?.market_price?.toFixed(1)
            : response?.data?.order_options?.sell?.market_price?.toFixed(1),
        );
        setQuantity(data?.quantity_slider?.values?.default_value);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getBestAvailablePriceMetaData(eventId),
          getBestAvailablePriceData(eventId),
        ]);
        setIsDataLoaded(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [eventId]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      const originURL = process.env.REACT_APP_PROBO_WEBSITE_URL;

      const { origin, data } = event || {};

      const { type } = data || {};

      if (origin === originURL && type === EVENT_TYPE.PAYMENT_SUCCESS) {
        getBestAvailablePriceMetaData(eventId);
        setEventType(type);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [eventId]);

  useEffect(() => {
    // Delay the height calculation to ensure the DOM is fully rendered
    const timer = setTimeout(() => {
      if (!isDataLoaded) {
        adjustHeightOnInitialLoad();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [isDataLoaded]);

  useEffect(() => {
    // 68px is the height of the recharge box (48px height + 20px top margin)
    if (isBalanceInsufficient && !insufficientBalanceOnInitialLoad) {
      setPriceSheetPageHeight(initialHeight + 68);
    } else if (eventType === EVENT_TYPE.PAYMENT_SUCCESS && !isBalanceInsufficient) {
      setPriceSheetPageHeight(Number(initialHeight) - 68);
    } else {
      setPriceSheetPageHeight(initialHeight);
    }
  }, [quantity, balance, eventType]);

  return (
    <div>
      <PartnerLayout height={priceSheetPageHeight} heightRef={heightRef}>
        <PartnerHeader partnerEventCardProps={eventData[eventId]} />
        {isDataLoaded ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <>
            <div className={styles['bottom__sheet__market__price__slider']}>
              <button
                className={styles['slider__action']}
                onClick={() => {
                  if (quantity > bestAvailablePriceData?.quantity_slider?.values?.min_value) {
                    const newQuantity = (
                      Number(quantity) -
                      Number(bestAvailablePriceData?.quantity_slider?.values?.tick_value)
                    )?.toFixed(1);

                    setQuantity(newQuantity);
                  }
                }}
              >
                <img src="https://trading.probo.in/static/media/minus.c620adbb.svg" />
              </button>
              <StyledSlider
                value={quantity}
                className={classNames({
                  [styles['slider']]: true,
                })}
                onChange={quantityChangeHandler}
                step={bestAvailablePriceData?.quantity_slider?.values?.tick_value}
                min={bestAvailablePriceData?.quantity_slider?.values?.min_value}
                max={bestAvailablePriceData?.quantity_slider?.values?.max_value}
              />
              <button
                className={styles['slider__action']}
                onClick={() => {
                  if (quantity < bestAvailablePriceData?.quantity_slider?.values?.max_value) {
                    const newQuantity = (
                      Number(quantity) +
                      Number(bestAvailablePriceData?.quantity_slider?.values?.tick_value)
                    )?.toFixed(1);

                    setQuantity(newQuantity);
                  }
                }}
              >
                <img src="https://trading.probo.in/static/media/plus.bd204a2f.svg" />
              </button>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <div>
                <div className={styles['bottom__sheet__market__price__slider_button__text']}>
                  ₹{price}
                </div>
                <div
                  className={styles['bottom__sheet__market__price__slider_label']}
                  style={{ marginTop: '5px' }}
                >
                  Price
                </div>
              </div>
              <div>
                <div className={styles['bottom__sheet__market__price__slider_button__text']}>
                  ₹{totalAmount.toFixed(1)}
                </div>
                <div
                  className={styles['bottom__sheet__market__price__slider_label']}
                  style={{ marginTop: '5px' }}
                >
                  You put
                </div>
              </div>
            </div>

            <div className={styles['order__place__button']}>
              <MuiButton
                style={{
                  background: `${actionType === ACTION_TYPE.YES ? '#0066FF' : '#CD0000'}`,
                  color: '#FFFFFF',
                  textTransform: 'capitalize',
                  width: '100%',
                }}
                className={classNames({
                  [styles['order__place__button__disabled']]: isBalanceInsufficient || isLoading,
                })}
                name={
                  <span style={{ fontSize: '12px', fontWeight: 600 }}>
                    place order at {actionType}
                    <br />
                    <span style={{ fontSize: '12px', fontWeight: 400 }}>
                      To Win ₹{(totalAmount + potentialWin)?.toFixed(1)}
                    </span>
                  </span>
                }
                disabled={isBalanceInsufficient || isLoading}
                onClick={() => handlePlaceOrder(eventId)}
              />
            </div>
            {isBalanceInsufficient && (
              <div className={styles['low__balance__banner']}>
                <div className={styles['low__balance__banner__info']}>
                  <img
                    className={styles['low__balance__banner__icon']}
                    // src={lowBalanceNudge?.icon.image_url || ''}
                    src={InsufficentBalance}
                    alt=""
                  />
                  <div className={styles['low__balance__banner__info__wrapper']}>
                    <div
                      className={styles['low__balance__banner__title']}
                      style={{
                        color: '#262626',
                      }}
                    >
                      {lowBalanceNudge?.title?.text}
                    </div>
                  </div>
                </div>

                <div className={styles['low__balance__banner__cta']}>
                  <MuiButton
                    className={styles['low__balance__banner__cta__btn']}
                    name={lowBalanceNudge?.cta?.text}
                    onClick={() => {
                      let URL = RECHARGE_URL;
                      const token = getPartnerAuthToken();
                      const sourceName = getSourceName();

                      if (!!token && sourceName) {
                        URL =
                          URL +
                          `?partner_auth_token=${encodeURIComponent(token)}&source_name=${sourceName}`;
                      }

                      window.open(URL, '_blank');
                    }}
                  />
                </div>
              </div>
            )}

            <div className={styles['balance__and__commission__section']}>
              <div>Balance: ₹{balance?.totalAmount || 0}</div>
              <div>
                <>
                  {commission?.text}: {commission?.value}%
                  {commission?.info_text && (
                    <span
                      style={{
                        marginLeft: '2px',
                        verticalAlign: 'middle',
                        position: 'relative',
                      }}
                    >
                      <Tooltip title={commission?.info_text}>
                        <span>
                          <img src={commission?.info_icon} width={12} height={12} />
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </>
              </div>
            </div>
          </>
        )}
      </PartnerLayout>
    </div>
  );
};

export default PartnerPriceSheet;
