import { toast, Zoom } from 'react-toastify';

// Types
export type ToastProps = {
  message: string;
  type: string;
};

let toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 2500,
  closeButton: true,
  hideProgressBar: true,
  draggable: false,
  icon: false,
  // transition: Zoom,
  className: 'toast__message',
};

export const Toast = ({ message, type }: ToastProps) => {
  const toastId = message;
  switch (type) {
    case 'success': {
      toast.success(message, {
        ...toastOptions,
        toastId,
        type: toast.TYPE.SUCCESS,
        style: { backgroundColor: '#05945B', color: 'white' },
      });
      break;
    }
    case 'error': {
      toast.error(message, {
        ...toastOptions,
        toastId,
        type: toast.TYPE.ERROR,
        style: { backgroundColor: '#B32306', color: 'white' },
      });
      break;
    }
    case 'info': {
      toast.info(message, {
        ...toastOptions,
        toastId,
        type: toast.TYPE.INFO,
        style: { backgroundColor: '#FFEDC8', color: 'black' },
      });
      break;
    }
    case 'warning': {
      toast.info(message, {
        ...toastOptions,
        toastId,
        type: toast.TYPE.INFO,
        style: { backgroundColor: '#A17217', color: 'white' },
      });
      break;
    }
    default:
      toast.dark(message, {
        ...toastOptions,
        toastId,
        type: toast.TYPE.DEFAULT,
      });
  }
  return null;
};

export const DimissToast = () => {
  toast.dismiss();
};
