import { useEffect, useState } from 'react';

import {
  getEventsData,
  getPartnerAuthToken,
  setEventsData,
  setPartnerId,
  setSourceName,
  setPartnerAuthToken,
  resolveTargetOrigin,
  getSourceName,
} from '../../config/helper';
import {
  APP_ENVIRONMENTS,
  EVENT_TYPE,
  LOCAL_STORAGE_KEY,
  URL_PARAMS_KEY,
} from '../../config/constants';

import {
  getEventsDataBasedOnPartnerCategory,
  getCategoryAndWidgetTypeUsingPartnerAccessAndPlacementId,
  getPublicEventDetails,
  createPartnerUserToken,
} from '../../services/partner';

import { Toast } from '../../components/Toast';

import PartnerEventCard from './PartnerEventCard';
import PartnerLayout from './PartnerLayout';
import CircularProgress from '@mui/material/CircularProgress';
import { EVENTS_URL } from '../../config/api';

const Partner = () => {
  const [data, setData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const placementID: string | null = urlParams.get(URL_PARAMS_KEY.PLACEMENT_ID);
  const accessID: string | null = urlParams.get(URL_PARAMS_KEY.ACCESS_ID);
  let categoryPassedByPartnerThroughUrl: string | null = urlParams.get(URL_PARAMS_KEY.CATEGORY);
  let categoryArray: string[] = [];

  if (categoryPassedByPartnerThroughUrl) {
    // Remove the leading and trailing brackets and quotes
    categoryPassedByPartnerThroughUrl = categoryPassedByPartnerThroughUrl?.replace(
      /^\[(["'])|(["'])\]$/g,
      '',
    );

    // Split the string by the appropriate separator
    categoryArray = categoryPassedByPartnerThroughUrl?.split(/["'],["']/);
  }

  let tokenValue: string | null = urlParams.get(URL_PARAMS_KEY.TOKEN_VALUE);

  if (tokenValue) {
    tokenValue = JSON.parse(atob(tokenValue));
  }

  const loggedIn: boolean = urlParams.get(URL_PARAMS_KEY.LOGGED_IN)?.toLocaleLowerCase() === 'true';

  const createUserToken = async (accessID: any, tokenValue: any) => {
    const response = await createPartnerUserToken(accessID, tokenValue);

    const { data, isError, message } = response || {};

    if (isError) {
      console.log('🚀 ~ createUserToken ~ message:', message);
    } else {
      const { authToken } = data || {};
      const sourceName = getSourceName();

      setPartnerAuthToken(authToken);
      setIsUserLoggedIn(true);
      const loginEventData = {
        type: EVENT_TYPE.LOGIN_SUCCESSFULLY,
        token: authToken,
        sourceName,
      };

      const targetOrigins = resolveTargetOrigin();

      if (targetOrigins) {
        targetOrigins.forEach((targetOrigin) => {
          (window as any).top.postMessage(loginEventData, targetOrigin);
        });
      }
    }
  };

  const handleErrorMessage = (message: string) => setErrorMessage(message);

  const handleEventDetailsPage = () => {
    if (isUserLoggedIn) {
      const eventSlug = data?.slug;

      if (eventSlug) {
        let URL = `${EVENTS_URL}/${eventSlug}`;

        const token = getPartnerAuthToken();
        const sourceName = getSourceName();

        if (!!token && sourceName) {
          URL = URL + `?partner_auth_token=${encodeURIComponent(token)}&source_name=${sourceName}`;
        }

        window.open(URL, '_blank');
      }
    }
  };

  useEffect(() => {
    const getPartnerWidgetDetails = async (placementID: any, accessID: any) => {
      try {
        const response = await getCategoryAndWidgetTypeUsingPartnerAccessAndPlacementId(
          placementID,
          accessID,
        );

        const { data, isError, message } = response || {};

        if (isError) {
          handleErrorMessage(message);
          console.log(
            '🚀 ~ getPartnerWidgetDetails ~ isError:',
            'PlacementID and AccessID is required to load a widget',
          );
        } else {
          let { categories, hyperlinks, partnerId, sourceName } = data || {};

          setPartnerId(partnerId);
          setSourceName(sourceName);

          if (categories?.length === 0 && (!categoryArray || categoryArray.length === 0)) {
            handleErrorMessage('Category is required to load a widget');
            console.log(
              '🚀 ~ getPartnerWidgetDetails ~ isError:',
              'Category is required to load a widget',
            );
          } else {
            const partnerCategories = (categoryArray?.length ?? 0) > 0 ? categoryArray : categories;

            const payload = {
              keywords: partnerCategories,
            };

            const response = await getEventsDataBasedOnPartnerCategory(payload);

            const { data, isError, message } = response || {};

            const { eventDetails } = data || {};

            if (isError) {
              Toast({
                message,
                type: 'error',
              });
              handleErrorMessage(message);
            } else {
              if (eventDetails?.length === 0) {
                handleErrorMessage(
                  'No events currently exist under the provided category. Please try a different category.',
                );
                console.log(
                  '🚀 ~ getPartnerWidgetDetails ~ isError:',
                  'No events currently exist under the provided category. Please try a different category.',
                );
              } else {
                const { id } = eventDetails[0] || {};
                const response = await getPublicEventDetails(Number(id));
                const { data, isError } = response || {};

                if (isError) {
                  handleErrorMessage(
                    'No events currently exist under the provided category. Please try a different category.',
                  );
                  console.log(
                    '🚀 ~ getPartnerWidgetDetails ~ isError:',
                    'No events currently exist under the provided category. Please try a different category.',
                  );
                } else {
                  let eventData: any = getEventsData();

                  // Parse the existing data if it exists
                  if (eventData) {
                    eventData = JSON.parse(eventData);
                  } else {
                    eventData = {};
                  }

                  // Update the existing data with new data from the API
                  if (data?.id) {
                    eventData[data.id] = data;
                  }

                  if (hyperlinks && hyperlinks.length > 0) {
                    eventData[data?.id].hyperlinks = hyperlinks;
                  }

                  setEventsData(JSON.stringify(eventData));
                  setData(data);
                }
              }
            }
          }
        }
      } catch (error) {
        console.log('🚀 ~ getPartnerWidgetDetails ~ error:', error);
      }
    };

    getPartnerWidgetDetails(placementID, accessID);
  }, [placementID, accessID]);

  useEffect(() => {
    const partnerAuthToken = getPartnerAuthToken();

    if (tokenValue && !partnerAuthToken) {
      createUserToken(accessID, tokenValue);
    }
  }, [tokenValue]);

  useEffect(() => {
    const token = getPartnerAuthToken();

    if (loggedIn && !!token) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
      localStorage.removeItem(LOCAL_STORAGE_KEY.PARTNER_AUTH_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_KEY.SOURCE_NAME_KEY);
    }
  }, [loggedIn]);

  if (!placementID || !accessID || errorMessage) {
    return <div />;
  }

  if (!data)
    return (
      <PartnerLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size="2rem" />
        </div>
      </PartnerLayout>
    );

  return (
    <div
      key={data?.id}
      style={{ width: '100%', cursor: isUserLoggedIn ? 'pointer' : 'auto' }}
      onClick={handleEventDetailsPage}
    >
      <PartnerEventCard event={data} hasPartnerLoggedIn={isUserLoggedIn} />
    </div>
  );
};

export default Partner;
