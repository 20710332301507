import { PartnerEventCardProps } from '../PartnerEventCard';

import BarChartIcon from '../../../assets/icons/bar-chart.png';

import styles from './style.module.scss';

type PartnerHeaderProps = {
  partnerEventCardProps?: PartnerEventCardProps;
  showTraders?: boolean;
};

const PartnerHeader = (props: PartnerHeaderProps) => {
  const { partnerEventCardProps, showTraders = true } = props;
  const { name, image_url, traders_count_numeric, traders_count_img } = partnerEventCardProps || {};

  return (
    <>
      <div className={styles['partner__header__wrapper']}>
        <div className={styles['partner__header__title']}>
          <span style={{ fontWeight: 400 }}>Powered by</span>&nbsp;
          <span style={{ fontWeight: 700 }}>
            <b>b.ox</b>
          </span>
        </div>
      </div>

      <div className={styles['event__card__info__wrapper']}>
        <div style={{ margin: showTraders ? 'unset' : '0px auto' }}>
          {showTraders && (
            <div className={styles['event__card__info__tags']}>
              <div>
                <img
                  className={styles['event__card__info__tag__icon']}
                  src={traders_count_img || BarChartIcon}
                  alt="bar-chart"
                />
              </div>

              <div className={styles['event__card__info__tag__name']}>
                {traders_count_numeric} traders
              </div>
            </div>
          )}
          {name && <div className={styles['event__card__info__name']}>{name}</div>}
        </div>
        <div>
          {image_url && (
            <div>
              <img className={styles['event__card__info__image']} src={image_url || ''} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerHeader;
