import React from 'react';
import ReactDOM from 'react-dom/client';

import './scss/global.scss';

import AppRouter from './router/index';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
);
