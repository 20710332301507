import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Partner from '../views/Partner';
import { setUserOs } from '../config/helper';
import PartnerPriceSheet from '../views/Partner/PartnerPriceSheet';
import SuccessCard from '../views/Partner/SuccessCard';
import { APP_ROUTES } from '../config/constants';

const AppRouter: React.FC = () => {
  useEffect(() => {
    setUserOs();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={APP_ROUTES.PARTNER} element={<Partner />} />
        <Route path={APP_ROUTES.PARTNER_PRICESHEET} element={<PartnerPriceSheet />} />
        <Route path={APP_ROUTES.PARTNER_SUCCESS} element={<SuccessCard />} />
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
