export enum WIDGETS_PLACEMENT_CONFIG_CARD_TYPE {
  CARD_WIDGET = 'card',
  CAROUSEL_WIDGET = 'carousel',
}

export enum LOCAL_STORAGE_KEY {
  PARTNER_AUTH_TOKEN_KEY = 'partner_auth_token',
  USER_OS_KEY = 'os',
  EVENT_DATA_KEY = 'event_data',
  PARTNER_HYPERLINKS_KEY = 'partner_hyperlinks',
  PARTNER_ID_KEY = 'partner_id',
  SOURCE_NAME_KEY = 'source_name',
}

export enum APP_ROUTES {
  PARTNER = '/partner',
  PARTNER_PRICESHEET = '/partner/priceSheet',
  PARTNER_SUCCESS = '/partner/success',
}

export enum URL_PARAMS_KEY {
  PLACEMENT_ID = 'placement_id',
  ACCESS_ID = 'access_id',
  CATEGORY = 'category',
  TOKEN_VALUE = 'token_value',
  LOGGED_IN = 'logged_in',
  ACTION_TYPE = 'action_type',
  LAST_TRADED_PRICE = 'last_traded_price',
  EVENT_ID = 'event_id',
}

export const Constants = {
  PROBO: 'probo',
  IOS: 'ios',
  ANDROID: 'android',
  PARTNER: 'partner',
  APP_VERSION: '10',
};

export enum PARTNER_SOURCE_NAME {
  THE_KREDIBLE = 'TheKredible',
  PROBO_THE_KREDIBLE = 'probo_thekredible',
}

export enum APP_ENVIRONMENTS {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

/*
export const PARTNER_TARGET_ORIGIN_URL_MAPPING: Record<PARTNER_SOURCE_NAME, string[] | undefined> =
  {
    [PARTNER_SOURCE_NAME.THE_KREDIBLE]: process.env.REACT_APP_PARTNERS_URLS?.split(','),
    [PARTNER_SOURCE_NAME.PROBO_THE_KREDIBLE]: process.env.REACT_APP_PARTNERS_URLS?.split(','),
  };
*/

export enum ACTION_TYPE {
  YES = 'Yes',
  NO = 'No',
}

export enum OFFER_TYPE {
  BUY = 'buy',
  SELL = 'sell',
}

export enum ORDER_TYPE {
  LO = 'LO',
}

export enum EVENT_TYPE {
  OPEN_LOGIN_PAGE = 'open_login_page',
  PAYMENT_SUCCESS = 'payment_success',
  LOGIN_SUCCESSFULLY = 'login_successfully',
  HEIGHT_CHANGE = 'height_change',
}
