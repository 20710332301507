import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import MuiButton from '../../../components/MuiButton';

import { handlePartnerLogin, resolveOneLinerVersion } from '../../../config/helper';
import { ACTION_TYPE, APP_ROUTES, URL_PARAMS_KEY } from '../../../config/constants';

import PartnerHeader from '../PartnerHeader';
import PartnerLogin from '../PartnerLogin';
import PartnerLayout from '../PartnerLayout';

import styles from './style.module.scss';

export type PartnerEventCardProps = {
  id: number | string;
  topicId?: number | string;
  categoryId?: number | string;
  top_information?: any;
  name: string;
  image_url: string;
  event_footer?: any;
  buy_button?: any;
  sell_button?: any;
  bottom_stripe?: any;
  yes_btn_text?: string;
  yes_price?: string;
  no_btn_text?: string;
  no_price?: string;
  event_templated_type?: string;
  last_traded_price_for_yes?: number;
  last_traded_price_for_no?: number;
  one_liner?: any;
  people_trading_image_url?: string;
  trading_info?: string;
  type: string;
  setBottomSheetEventId: Function;
  setBottomSheetOpen: Function;
  setBottomSheetAction: Function;
  setShowTour?: Function;
  eventPage?: string;
  oneliner_version?: string;
  oneliner_v2?: any;
  traders_count_numeric?: number;
  traders_count_img?: string;
};

type HandleClick = {
  id?: number | string;
  e: any;
  event?: any;
  action__type?: string;
  action_name?: string;
};

const PartnerEventCard = ({ event, hasPartnerLoggedIn }: any) => {
  const navigate = useNavigate();

  const [eventCardPageHeight, setEventCardPageHeight] = useState<string | number>('100%');

  const heightRef: any = useRef(null);

  const {
    id,
    buy_button,
    sell_button,
    yes_btn_text,
    no_btn_text,
    last_traded_price_for_yes,
    last_traded_price_for_no,
    one_liner,
    type,
    oneliner_version,
    oneliner_v2,
  } = event;

  const handleClick = ({ e, id, event, action__type }: HandleClick) => {
    e.stopPropagation();

    if (event && action__type) {
      if ([ACTION_TYPE.YES, ACTION_TYPE.NO].includes(action__type as ACTION_TYPE)) {
        if (!hasPartnerLoggedIn) {
          handlePartnerLogin();
        } else {
          let url: string = APP_ROUTES.PARTNER_PRICESHEET;

          if (action__type === ACTION_TYPE.YES) {
            url = `${url}?${URL_PARAMS_KEY.ACTION_TYPE}=${action__type}&${URL_PARAMS_KEY.EVENT_ID}=${id}`;
          }

          if (action__type === ACTION_TYPE.NO) {
            url = `${url}?${URL_PARAMS_KEY.ACTION_TYPE}=${action__type}&${URL_PARAMS_KEY.EVENT_ID}=${id}`;
          }

          navigate(url);
        }
      }
    }
  };

  if (!['probabilistic', 'versus'].includes(type)) {
    return null;
  }

  const oneLinerText: any = resolveOneLinerVersion(oneliner_version, one_liner, oneliner_v2);

  useEffect(() => {
    if (event && heightRef?.current) {
      const timer = setTimeout(() => {
        if (heightRef.current) {
          // 4px is hack to remove scroll on iframe
          setEventCardPageHeight(heightRef.current.clientHeight + 4);
        }
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [event, hasPartnerLoggedIn]);

  // useEffect(() => {
  //   if (event && heightRef?.current) {
  //     const observer = new ResizeObserver((entries) => {
  //       console.log('🚀 ~ observer ~ entries:', entries);
  //       setEventCardPageHeight(heightRef.current.clientHeight + 4);
  //     });

  //     const mutationObserver = new MutationObserver(() => {
  //       setEventCardPageHeight(heightRef.current.clientHeight + 4);
  //     });

  //     observer.observe(heightRef.current);
  //     mutationObserver.observe(heightRef.current, { childList: true, subtree: true });

  //     return () => {
  //       observer.disconnect();
  //       mutationObserver.disconnect();
  //     };
  //   }
  // }, [event, hasPartnerLoggedIn]);

  return (
    <PartnerLayout height={eventCardPageHeight} heightRef={heightRef}>
      <div className={styles['event__card__wrapper']}>
        <div className={styles['event__card']}>
          <div>
            <PartnerHeader partnerEventCardProps={event} />

            <div className={styles['event__card__probability']}>
              {last_traded_price_for_yes * 10}% probability of yes
            </div>
            <div className={styles['event__card__one__liner__name']}>{oneLinerText}</div>

            <div>
              <div className={styles['event__card__actions__button__view']}>
                {(buy_button?.text || yes_btn_text) && (
                  <MuiButton
                    className={classNames({
                      [styles['event__card__actions__button']]: true,
                      [styles['event__card__actions__button__yes']]: true,
                    })}
                    style={{
                      background: buy_button?.bg_color,
                      color: buy_button?.text_color,
                    }}
                    name={buy_button?.text || `${yes_btn_text} ₹${last_traded_price_for_yes}`}
                    onClick={(e: any) => {
                      handleClick({ e, event, id, action__type: ACTION_TYPE.YES });
                    }}
                  />
                )}

                {(sell_button?.text || no_btn_text) && (
                  <MuiButton
                    className={classNames({
                      [styles['event__card__actions__button']]: true,
                      [styles['event__card__actions__button__no']]: true,
                    })}
                    style={{
                      background: sell_button?.bg_color,
                      color: sell_button?.text_color,
                    }}
                    name={sell_button?.text || `${no_btn_text} ₹${last_traded_price_for_no}`}
                    onClick={(e: any) => {
                      handleClick({ e, event, id, action__type: ACTION_TYPE.NO });
                    }}
                  />
                )}
              </div>
            </div>

            <PartnerLogin hasPartnerLoggedIn={hasPartnerLoggedIn} />
          </div>
        </div>
      </div>
    </PartnerLayout>
  );
};

export default PartnerEventCard;
